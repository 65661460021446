//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  data() {
    return {
      showCart: false
    }
  },
  computed: {
    ...mapState([ 'showNav', 'order', 'cartCount' ]),
    cartCount() {
      return this.order ? this.order.products.length : 0
    },
    isMobile() { return this.$breakpoints.width < 900 },
  },
  mounted() {
    if (this.$breakpoints.width < 900 || this.cartCount) {
      this.showCart = true
    } else {
      this.showCart = false
    }
    window.addEventListener('resize',this.resize)
  },
  destroyed() {
    window.removeEventListener('resize',this.resize)
  },
  watch: {
    '$route.path'(e) {
      // this.$nextTick(() => {
        if (this.$breakpoints.width < 900 || this.cartCount) {
          this.showCart = true
        } else {
          this.showCart = false
        }
      // })
    }
  },
  methods: {
    resize() {
      // this.$nextTick(() => {
        if (this.$breakpoints.width < 900 || this.cartCount) {
          this.showCart = true
        } else {
          this.showCart = false
        }
      // })
    }
  }
}
