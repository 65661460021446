//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import moment from 'moment';

export default {
  data() {
    return {
      'showStickyHeader': false
    }
  },
  computed: {
    ...mapState([ 'token', 'location', 'order', 'menu', 'loading', 'errors', 'showBanner', 'opaque', 'timePicker', 'orderTypePicker', 'addressPicker' ]),
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch(err) {
      console.error(err)
    }
  },
  async validate({ $storyblok, params }) {
    return await $storyblok.getStory(params.pathMatch || 'home')
  },
  async asyncData({ $storyblok, params }) {
    let story = await $storyblok.getStory(params.pathMatch || 'home')
    return {
      story
    }
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch(err) {
      console.error(err)
    }
    if(this.$route.query.store) {
      await this.$api.getLocation(this.$route.query.store)
      await this.$api.getMenu(true, false)
      if(this.order && this.order.menu_id != this.location.menu_id) {
        await this.$api.transferOrder()
      }
    } else if(this.$route.query.menu_id) {
      await this.$api.getLocation(this.$route.query.menu_id, true)
      await this.$api.getMenu(true, false)
      if(this.order && this.order.menu_id != this.location.menu_id) {
        await this.$api.transferOrder()
      }
    }
    if(this.token && !this.token.provider) {
      this.$store.commit('setToken', null)
      this.$store.commit('setUser', null)
    }
    if(this.location) {
      await this.$api.getMenu(true, false)
    }
    this.checkOrderStatus()
    window.addEventListener('scroll',this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions([ 'fanFave' ]),
    handleScroll() {
      if (window.innerWidth >= 900 && window.scrollY > 220) {
        this.showStickyHeader = true
      } else {
        this.showStickyHeader = false
      }
    },
    checkOrderStatus() {
      if (this.order) {
        const created = moment(this.order.created,'YYYYMMDD HH:mm')
        const hours = moment(Date.now()).diff(created, 'hours')
        if (hours > 24) {
          this.$store.commit('setErrors', ['Your basket has expired. Restart your order to continue.'])
          this.$store.commit('setLocation', this.location)
          this.$store.commit('setMenu', null)
          this.$store.commit('setOrder', null)
          // this.$store.commit('setOrderTypePicker', true)
          setTimeout(function() {
            this.$router.push('/')
          }.bind(this), 2000)
        }
      }
    }
  },
  watch: {
    '$route.path'(e) {
      this.$store.commit('setErrors', [])
      if (this.$mq == 'sm' || this.$mq == 'md') {
        this.$store.commit('setShowNav', false)
      }
      window.scroll({ top: 0, left: 0, behavior: 'smooth' }) // scroll to top on route change
      this.checkOrderStatus()
    }
  }
}
