//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  data() {
    return {
      'mounted': false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.mounted = true
    })
  },
  computed: {
    ...mapState([ 'customer', 'order', 'options', 'location' ]),
    cartCount() {
      return this.order ? this.order.products.length : 0
    },
    cartLink() {
      let link = this.options.orderPage
      // if(this.order && this.order.products.length > 0) {
      //   if(this.customer.type != null) {
      //     link = this.options.orderCheckoutPage
      //   } else if(this.options.guestCheckout) {
      //     link = this.options.orderAccountPage
      //   } else {
      //     link = this.options.signinPage
      //   }
      // }
      if(!this.location) {
        link = this.options.locationsPage
      }
      return link
    }
  }
}
