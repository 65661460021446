//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
  export default {
    computed: {
      ...mapState([ 'order', 'options', 'customer' ]),
    cartLink() {
      let link = this.options.orderPage
      if(this.order && this.order.products.length > 0) {
        if(this.customer.type != null) {
          link = this.options.orderCheckoutPage
        } else if(this.options.guestCheckout) {
          link = this.options.orderAccountPage
        } else {
          link = this.options.signinPage
        }
      }
      return link
    }
    }
  }
