//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'icons' ]),
    isMobile() { return this.$breakpoints.width < 900 }
  },
  props: {
    white: {
      type: Boolean,
    },
  }
}
