import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=e08cfaa2&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavLink: require('/opt/build/repo/components/common/NavLink.vue').default,FooterLogo: require('/opt/build/repo/components/layout/FooterLogo.vue').default,IconFacebook: require('/opt/build/repo/components/icons/IconFacebook.vue').default,IconTwitter: require('/opt/build/repo/components/icons/IconTwitter.vue').default,IconInstagram: require('/opt/build/repo/components/icons/IconInstagram.vue').default,IconYoutube: require('/opt/build/repo/components/icons/IconYoutube.vue').default,Footer: require('/opt/build/repo/components/layout/Footer.vue').default})
