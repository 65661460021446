//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'user', 'location', 'topBanner' ])
  },
  methods: {
    hide() {
      this.$store.commit('setShowBanner', false)
    }
  }
}
