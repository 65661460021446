//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState([ 'errors', 'redirect' ])
  },
  methods: {
    clearErrors() {
      if(this.redirect && !this.redirect.match(/undefined/)) {
        let redirect = this.redirect
        this.$store.commit('setRedirect', null)
        this.$router.push(redirect)
      }
      this.$store.commit('setErrors', [])
    }
  }
}
