import oloList from '../assets/data/olo.json'

export default (context, inject) => {
  const api = {
    async request(obj, verify = false, surpress = false, loader = true, full = false, target = null) {
      obj.url = `${window.location.origin}/api/v1/${obj.headers.path}`
      delete obj.headers.path
      if(process.env.REQUIRE_VERIFY && verify) {
        obj.headers.verify = await context.$recaptcha.execute('login')
      }
      if(target) {
        obj.headers.env = target
      // } else if(context.store.state.location && oloList.indexOf(context.store.state.location.id) < 0) {
      //   obj.headers.env = 'legacy'
      } else if(process.env.API_ENV) {
        obj.headers.env = process.env.API_ENV
      } else if(context.store.state.env) {
        obj.headers.env = context.store.state.env
      }
      obj.headers.client_type = context.store.state.client
      context.store.commit('setErrors', [])
      if(loader) {
        context.store.commit('setLoading', true)
      }
      return context
        .$axios(obj)
        .then((res) => {
          if(loader) {
            context.store.commit('setLoading', false)
          }
          if(full) {
            return res
          } else {
            return res.data
          }
        })
        .catch((err) => {
          if(loader) {
            context.store.commit('setLoading', false)
          }
          if(surpress === false) {
            if(Array.isArray(err.response.data)) {
              if(typeof err.response.data[0] === 'string') {
                context.store.commit('setErrors', err.response.data)
              } else {
                let errors = []
                err.response.data.map(e => {
                  errors.push(e.message)
                })
                context.store.commit('setErrors', errors)
              }
            } else if(err.response.data.message) {
              if(err.response.data.message == 'This cart has expired. Please start a new order.') {
                context.store.commit('setOrder', null)
                context.store.commit('setErrors', ['There was an issue with your cart. Please start a new order.'])
                if(context.route.path.match(/\/order\/checkout/)) {
                  context.store.commit('setRedirect', '/order')
                }
              } else if (err.response.data.message === 'Invalid postal code') {
                context.store.commit('setDeliveryErrors', [err.response.data])
              } else {
                context.store.commit('setErrors', [err.response.data.message])
              }
            } else {
              context.store.commit('setErrors', [
                'Connection issue. Please try again.'
              ])
            }
          }
          return null
        })
    },
    // Email subscription
    async subscribe(customer) {
      const subscription = await this.request({
        method: 'post',
        headers: {
          path: 'eclub/subscribe'
        },
        data: customer
      })
      return subscription
    },
    // Users
    async createUser(customer) {
      const user = await this.request({
        method: 'post',
        headers: {
          path: 'members/create'
        },
        data: customer
      }, true)
      if(user) {
        context.store.commit('setToken', user.tokens)
      }
      return user
    },
    async loginUser(creds) {
      const user = await this.request({
        method: 'post',
        headers: {
          path: 'members/signin'
        },
        data: creds
      }, true)
      if(user) {
        context.store.commit('setToken', user.tokens)
      }
      return user
    },
    async loginSSO(creds) {
      let user = await this.request({
        method: 'post',
        headers: {
          path: 'members/sso',
        },
        data: creds
      })
      if(user) {
        context.store.commit('setToken', user.tokens)
      }
      return user
    },
    async getUser(withTokens = false) {
      let request = {
        method: 'get',
        headers: {
          path: 'members/get',
          token: context.store.state.token.access.token
        }
      }
      if(context.store.state.token && context.store.state.token.id) {
        request.params = {
          memberID: context.store.state.token.id
        }
      }
      if(withTokens) {
        if(!request.params) {
          request.params = {
            withTokens: true
          }
        } else {
          request.params.withTokens = true
        }
      }
      const user = await this.request(request)
      if(user) {
        context.store.commit('setUser', user)
      }
      return user
    },
    async updateUser(d) {
      let request = {
        method: 'put',
        headers: {
          path: 'members/update',
          token: context.store.state.token.access.token
        },
        data: d
      }
      if(context.store.state.token && context.store.state.token.id) {
        request.params = {
          memberID: context.store.state.token.id
        }
      }
      const user = await this.request(request)
      if(user) {
        context.store.commit('setUser', d)
      }
      return user
    },
    async setFaveLocation(id) {
      const fave = await this.request({
        method: 'post',
        headers: {
          path: `members/faves/locations/${id}`,
          token: context.store.state.token.ordering.access.token
        }
      })
      return fave
    },
    async deleteFaveLocation(id) {
      const fave = await this.request({
        method: 'delete',
        headers: {
          path: `members/faves/locations/${id}`,
          token: context.store.state.token.ordering.access.token
        }
      })
      if(fave != null) {
        const user = JSON.parse(JSON.stringify(context.store.state.user))
        user.favelocations.map((f, i) => {
          if(f.menu_id == id) {
            user.favelocations.splice(i, 1)
          }
        })
        context.store.commit('setUser', user)
      }
      return fave
    },
    // Payments
    async getPayments() {
      if(context.store.state.token && context.store.state.token.ordering) {
        let request = {
          method: 'get',
          headers: {
            path: `members/payments`,
            token: context.store.state.token.ordering.access.token
          }
        }
        if(context.store.state.token && context.store.state.token.id) {
          request.params = {
            memberID: context.store.state.token.id
          }
        }
        const payments = await this.request(request)
        if(payments) {
          const user = JSON.parse(JSON.stringify(context.store.state.user))
          user.payments = payments.payments
          context.store.commit('setUser', user)
        }
        return payments
      } else {
        return null
      }
    },
    async deletePayment(pid) {
      let request = {
        method: 'delete',
        headers: {
          path: `members/payments/${pid}`,
          token: context.store.state.token.ordering.access.token
        }
      }
      if(context.store.state.token && context.store.state.token.id) {
        request.params = {
          memberID: context.store.state.token.id
        }
      }
      const payments = await this.request(request)
      if(payments != null) {
        const user = JSON.parse(JSON.stringify(context.store.state.user))
        user.payments.map((p, i) => {
          if(p.account.id == pid) {
            user.payments.splice(i, 1)
          }
        })
        context.store.commit('setUser', user)
      }
      return payments
    },
    getPaymentForm() {
      return this.request({
        method: 'post',
        headers: {
          path: `payments/form`
        },
        data: {
          id: context.store.state.token.payments.access.id
        }
      })
    },
    submitPayment(account) {
      return this.request({
        method: 'post',
        headers: {
          path: `payments/submit`,
          token: context.store.state.token.ordering.access.token,
          payment_token: context.store.state.token.payments.access.token
        },
        data: {
          id: context.store.state.token.payments.access.id,
          order_id: context.store.state.order.id,
          store_id: context.store.state.order.menu_id,
          amount: context.store.state.order.totals.total,
          account
        }
      })
    },
    // Addresses
    async getAddresses() {
      let request = {
        method: 'get',
        headers: {
          path: `members/addresses`,
          token: context.store.state.token.ordering.access.token
        }
      }
      if(context.store.state.token && context.store.state.token.id) {
        request.params = {
          memberID: context.store.state.token.id
        }
      }
      const addresses = await this.request(request)
      if(addresses) {
        const user = JSON.parse(JSON.stringify(context.store.state.user))
        user.addresses = addresses.addresses
        context.store.commit('setUser', user)
      }
    },
    async deleteAddress(aid) {
      let request = {
        method: 'delete',
        headers: {
          path: `members/addresses/${aid}`,
          token: context.store.state.token.ordering.access.token
        }
      }
      if(context.store.state.token && context.store.state.token.id) {
        request.params = {
          memberID: context.store.state.token.id
        }
      }
      const addresses = await this.request(request)
      const user = JSON.parse(JSON.stringify(context.store.state.user))
      user.addresses.map((a, i) => {
        if(a.id == aid) {
          user.addresses.splice(i, 1)
        }
      })
      context.store.commit('setUser', user)
      return addresses
    },
    // Passwords
    async changePassword(newpassword, oldpassword) {
      let request = {
        method: 'post',
        headers: {
          path: 'members/password',
          token: context.store.state.token.access.token
        },
        data: {
          new: newpassword,
          current: oldpassword
        }
      }
      if(context.store.state.token && context.store.state.token.id) {
        request.params = {
          memberID: context.store.state.token.id
        }
      }
      return await this.request(request)
    },
    async forgotPassword(email) {
      return await this.request({
        method: 'post',
        headers: {
          path: 'members/forgotpassword'
        },
        data: {
          email
        }
      }, true)
    },
    // Locations
    async findLocations(loc) {
      const locations = await this.request({
        method: 'get',
        headers: {
          path: 'locations/search'
        },
        params: loc
      }, false, false, true, false)
      // const legacy_locations = await this.request({
      //   method: 'get',
      //   headers: {
      //     path: 'locations/search'
      //   },
      //   params: loc
      // }, false, false, true, false, 'legacy')
      if(locations/* || legacy_locations*/) {
        let combined_locations = []
        locations.locations.map(l => {
          combined_locations.push(l)
        })
        // legacy_locations.locations.map(l => {
        //   combined_locations.push(l)
        // })
        // combined_locations.sort((a, b) => { return a.distance < b.distance ? -1 : 1 })
        if(combined_locations.length > 0) {
          context.store.commit('setLocationList', combined_locations)
        } else {
          context.store.commit('setLocationList', false)
        }
      } else {
        context.store.commit('setLocationList', null)
      }
      return locations
    },
    async getLocation(id, byMenuId = false) {
      const request = {
        method: 'get',
        headers: {
          path: `locations/${id}`
        }
      }
      if(byMenuId) {
        request.params = {
          byMenuId: true
        }
      }
      const location = await this.request(request)
      if(location) {
        context.store.commit('setLocation', location)
      }
      return location
    },
    // Menus
    async getMenu(surpress = false, loader = true, handoff_time = null) {
      let request = {
        method: 'get',
        headers: {
          path: `menus/${context.store.state.location.menu_id}`
        }
      }
      if(handoff_time) {
        request.params = {
          time: handoff_time
        }
      }
      const menu = await this.request(request, false, surpress, loader)
      if(menu) {
        context.store.commit('setMenu', menu)
        return true
      } else {
        return false
      }
    },
    async getMenuProducts(id) {
      const products = await this.request({
        method: 'get',
        headers: {
          path: `menus/${context.store.state.location.menu_id}/categories/${id}`
        }
      })
      if(products) {
        let menu = JSON.parse(JSON.stringify(context.store.state.menu))
        menu.categories.map(c => {
          if(c.id == id) {
            c.products = products.products
          }
        })
        context.store.commit('setMenu', menu)
      }
    },
    getProduct(pid) {
      return this.request({
        method: 'get',
        headers: {
          path: `menus/${context.store.state.location.menu_id}/products/${pid}`
        }
      })
    },
    // Order History
    async getRecentOrders() {
      let request = {
        methods: 'get',
        headers: {
          path: 'orders/recent',
          token: context.store.state.token.ordering.access.token
        }
      }
      if(context.store.state.token && context.store.state.token.id) {
        request.params = {
          memberID: context.store.state.token.id
        }
      }
      const orders = await this.request(request)
      if(orders) {
        context.store.commit('setOrderHistory', orders.orders)
      }
      return orders
    },
    async createReorder(id) {
      const order = await this.request({
        method: 'post',
        headers: {
          path: 'orders/reorder',
          token: context.store.state.token.ordering.access.token
        },
        data: {
          id
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    // Favorite Orders
    async getFaveOrders() {
      const orders = await this.request({
        method: 'get',
        headers: {
          path: 'orders/faves',
          token: context.store.state.token.ordering.access.token
        }
      })
      return orders
    },
    async createFaveOrder(id, name) {
      const order = await this.request({
        method: 'post',
        headers: {
          path: 'orders/faves',
          token: context.store.state.token.ordering.access.token
        },
        data: {
          id,
          description: name
        }
      })
      return order
    },
    async createFaveReorder(id) {
      const order = await this.request({
        method: 'post',
        headers: {
          path: 'orders/faves/reorder',
          token: context.store.state.token.ordering.access.token
        },
        data: {
          id
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    // Orders
    async createOrder(handoff_time = null) {
      let request = {
        method: 'post',
        headers: {
          path: 'orders/create'
        },
        data: {
          menu_id: context.store.state.location.menu_id,
          menu_type: context.store.state.location.menu_type
        }
      }

      if(handoff_time) {
        request.data.time = handoff_time
      }

      if(context.store.state.token) {
        request.headers.token = context.store.state.token.ordering.access.token
      }
      
      let order = await this.request(request)
      if(order) {
        console.log('test')
        context.store.commit('setOrder', order)
      }
      return order
    },
    async transferOrder() {
      let order = null 
      await this.request({
        method: 'post',
        headers: {
          path: `orders/${context.store.state.order.id}/transfer`
        },
        data: {
          menu_id: context.store.state.location.menu_id
        }
      }, false, true)
      if(order) {
        context.store.commit('setOrder', order)
      } else {
        order = await this.createOrder()
      }
      return order
    },
    async retrieveOrder(oid) {
      const order = await this.request({
        method: 'get',
        headers: {
          path: `orders/${oid}`
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async addToOrder(prods) {
      if(!context.store.state.order) {
        await this.createOrder(context.store.state.location.menu_id)
      }
      const order = await this.request({
        method: 'post',
        headers: {
          path: `orders/${context.store.state.order.id}/products`
        },
        data: {
          products: prods,
          menu_id: context.store.state.order.menu_id,
          menu_type: context.store.state.location.menu_type,
          handoff_type: context.store.state.order.handoff
        }
      }, false, false, false)
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async updateInOrder(prods) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/products`
        },
        data: {
          products: prods,
          menu_id: context.store.state.order.menu_id,
          menu_type: context.store.state.location.menu_type,
          handoff_type: context.store.state.order.handoff
        }
      }, true, false, false)
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async removeFromOrder(pid) {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/products/${pid}`
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async validateOrder() {
      let request = {
        method: 'post',
        headers: {
          path: `orders/${context.store.state.order.id}/validate`
        }
      }
      if(context.store.state.token && context.store.state.token.id && context.store.state.token.access) {
        request.params = {
          memberID: context.store.state.token.id
        }
        request.headers.token = context.store.state.token.access.token
      }
      const totals = await this.request(request)
      if(totals) {
        const order = JSON.parse(JSON.stringify(context.store.state.order))
        order.taxes = totals.taxes
        order.totals.tax = totals.totals.tax
        order.totals.total = totals.totals.total
        if(order.time.mode == 'asap') {
          order.time.earliest = totals.time
        }
        context.store.commit('setOrder', order)
        context.store.commit('setSupports', totals.supports)
        totals.billing.map(b => {
          if(b.type == 'creditcard' && b.accounts.length > 0) {
            context.store.commit('setAccounts', b.accounts)
            context.store.commit('setSavedPayments', true)
            b.accounts.map(a => {
              if(a.account.default) {
                context.store.commit('setSelectedAccount', a.account.id)
              }
            })
          } else if(b.type == 'creditcard') {
            context.store.commit('setAccounts', [])
            context.store.commit('setSavedPayments', false)
          } else if(b.type == 'payinstore') {
            context.store.commit('setInstorePaymentID', b.id)
          }
        })
      }
      return totals
    },
    async setHandoff(mode) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/handoff/${mode}`
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async setAddress(address) {
      const type = context.store.state.location.services.dispatch ? 'dispatch' : 'delivery'
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/address`
        },
        data: {
          type,
          address
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async setCustomFields(fields) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/custom`
        },
        data: {
          fields
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    getCalendar(from, to) {
      return this.request({
        method: 'get',
        headers: {
          path: `menus/${context.store.state.location.menu_id}/times`
        },
        params: {
          from,
          to
        }
      })
    },
    async setTime(time) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/time`
        },
        data: {
          time
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async deleteTime() {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/time`
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async addTip(amount) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/tip`
        },
        data: {
          amount
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async addCoupon(code) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/coupon`
        },
        data: {
          code
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async removeCoupon() {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/coupon`
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    getBasketToken() {
      let headers = {
        path: `orders/${context.store.state.order.id}/token`
      }
      if(context.store.state.token) {
        headers.token = context.store.state.token.ordering.access.token
      }
      return this.request({
        method: 'post',
        headers
      })
    },
    submitOrder(billing) {
      let headers = {
        path: `orders/${context.store.state.order.id}/submit`
      }
      let data = {
        usertype: 'guest',
        payments: billing
      }
      if(context.store.state.token && context.store.state.token.ordering.access.token) {
        data.usertype = 'user'
        headers.token = context.store.state.token.ordering.access.token
      }
      data.first_name = context.store.state.customer.user.first_name
      data.last_name = context.store.state.customer.user.last_name
      data.email = context.store.state.customer.user.email
      data.phone = context.store.state.customer.user.phone.replace(/[\(\)\-\s]/g, '')
      data.age_confirmed = true
      return this.request({
        method: 'post',
        headers,
        data
      })
    },
    async lookupOrder(bid) {
      let lookup = await this.request({
        method: 'get',
        headers: {
          path: `orders/${bid}/lookup`
        }
      }, false, false, false, true)
      console.log('lookup', lookup)
      if(lookup) {
        return lookup
      } else {
        return {
          status: 400,
          data: context.store.state.errors.length > 0 ? context.store.state.errors[0] : 'Connection issue. Please try again.'
        }
      }
    },
    trackConversion(bid, oid) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${bid}/track`
        },
        data: {
          id: oid
        }
      })
    },
    getOrderStatus(id) {
      let request = {
        method: 'get',
        headers: {
          path: `orders/${id}/status`
        }
      }
      if(context.store.state.token && context.store.state.token.ordering.access.token && context.store.state.token.id) {
        request.headers.token = context.store.state.token.ordering.access.token
        request.params = {
          memberID: context.store.state.token.id
        }
      }
      return this.request(request)
    },
    cancelOrder(id) {
      return this.request({
        method: 'delete',
        headers: {
          path: `orders/${id}`
        }
      })
    }
  }

  inject('api', api)
  context.$api = api
}
