export default (context, inject) => {
  const payments = {
    delay(ms = 5000) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async getKey() {
      const config = {
        method: 'get',
        url: `${window.location.origin}/paymentkey`,
        headers: {
          env: process.env.API_ENV ? process.env.API_ENV : context.store.state.env
        }
      }
      return await context.$axios(config)
        .then(res => {
          return res.data
        })
    },
    async submit(data, tokenize = false) {
      let config = {
        method: 'post',
        url: `${window.location.origin}/payment-${process.env.API_PAYMENT_TEST ? 'test' : 'background'}`,
        headers: {
          verify: await context.$recaptcha.execute('login'),
          order_id: context.store.state.order.id,
          env: process.env.API_ENV ? process.env.API_ENV : context.store.state.env,
          client_type: context.store.state.client,
        },
        data
      }
      if(tokenize) {
        config.headers.tokenize = true
      }
      if(context.store.state.token && context.store.state.token.access.token) {
        config.headers.token = context.store.state.token.access.token
      }
      context.store.commit('setLoading', true)
      let resStatus = await context.$axios(config)
        .then(res => {
          return res.status
        })
        .catch(err => {
          return null
        })
      if(resStatus == 202) {
        let lookup = {
          status: 202
        }
        while(lookup.status == 202) {
          await this.delay(1000)
          lookup = await context.$api.lookupOrder(context.store.state.order.id)
        }
        if(lookup.status == 400) {
          context.store.commit('setSubmitting', false)
          context.store.commit('setLoading', false)
          context.store.commit('setErrors', [lookup.data])
        } else {
          context.store.commit('setOrder', null)
          context.store.commit('setSubmitting', false)
          this.trackOrder()
          return lookup.data
        }
      } else {
        context.store.commit('setSubmitting', false)
        context.store.commit('setLoading', false)
      }
    },
    trackOrder() {
      const products = JSON.parse(JSON.stringify(context.store.state.order))
      if(products && context.store.state.location) {
        const purchases = []
        products.products.map((p) => {
          purchases.push({
            name: p.name,
            price: p.cost.total,
            quantity: p.quantity,
            sku: p.product_id
          })
        })
        const transactionDetails = {
          ecommerce: {
            purchase: {
              actionField: {
                id: products.id,
                revenue: products.totals.total,
                tax: products.totals.tax
              },
              storeNumber: context.store.state.id,
              zipCode: context.store.state.zip,
              products: purchases
            }
          }
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(transactionDetails)
      }
    }
  }

  inject('payments', payments)
  context.$payments = payments
}