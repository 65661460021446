//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapState } from 'vuex'

export default {
  props: {
    onSelect: {
      type: Function,
    },
    lang: {
      type: String,
      default: 'Update'
    }
  },
  computed: {
    ...mapState([ 'location', 'order', 'deliveryErrors', 'addingProduct' ]),
    duringCheckout() {
      if (this.$route.path.match(/order\/checkout/)) {
        return true
      } else {
        return false
      }
    }
  },
  data() {
    return {
      orderType: null,
      street: '', 
      aptNum: '', 
      city: '',
      state: '',
      zip: '',
      instructions: '',
    }
  },
  async mounted() {
    console.log(this.duringCheckout)
    // if(this.order && (this.order.handoff == 'dispatch' || this.order.handoff == 'delivery')) {
    //   method = 'Delivery'
    // } else if(this.order && this.order.handoff == 'curbside') {
    //   method = 'Curbside'
    // }
  },
  methods: {
    resetError() {
      let noErrors = []
      this.$store.commit('setDeliveryErrors', noErrors)
    },
    setPickup() {
      let noErrors = []
      this.$store.commit('setDeliveryErrors', noErrors)
      this.$store.commit('setHandoff', 'pickup')
      this.$store.commit('setAddressPicker', false)
      this.$store.commit('setTimePicker', true)
    },
    closeModal() {
      this.$store.commit('setAddressPicker', false)
    },
    goBack() {
      this.$store.commit('setAddressPicker', false)
      this.$store.commit('setOrderTypePicker', true)
    },
    async setAddress() {
      console.log('test')
      let address = {
        address: this.street,
        building: this.aptNum,
        city: this.city,
        state: this.state,
        zip: this.zip,
        instructions: this.instructions
      }
      let order = await this.$api.setAddress(address)
      if (order) {
        this.$store.commit('setAddressPicker', false)
        this.$store.commit('setHandoff', this.location.services.dispatch ? 'dispatch' : 'delivery')
      }
    },
    async createDeliveryOrder() {
      let address = {
        address: this.street,
        building: this.aptNum,
        city: this.city,
        state: this.state,
        zip: this.zip,
        instructions: this.instructions
      }
      let order = await this.$api.setAddress(address)
      if (order) {
        this.$store.commit('setAddressPicker', false)
        this.$store.commit('setTimePicker', true)
      }
    },
  }
}
