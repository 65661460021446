import { render, staticRenderFns } from "./StickyHeader.vue?vue&type=template&id=9cff09ba&"
import script from "./StickyHeader.vue?vue&type=script&lang=js&"
export * from "./StickyHeader.vue?vue&type=script&lang=js&"
import style0 from "./StickyHeader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Hamburger: require('/opt/build/repo/components/layout/Hamburger.vue').default,Logo: require('/opt/build/repo/components/layout/Logo.vue').default,NavMenu: require('/opt/build/repo/components/layout/NavMenu.vue').default,Header: require('/opt/build/repo/components/layout/Header.vue').default})
