import moment from 'moment'
// import oloList from '../assets/data/olo.json'

export const state = () => ({
  client: 'web',
  env: null,
  loading: false,
  errors: [],
  deliveryErrors: [],
  location: null,
  locationList: null,
  allLocations: [],
  defaultMenu: null,
  menu: null,
  order: null,
  checkoutStep: 0,
  showCart: false,
  user: null,
  token: null,
  orderHistory: null,
  redirect: null,
  presubmitting: false,
  submitting: false,
  navMenus: {},
  options: {},
  icons: {},
  promo: {},
  showNav: false,
  showBanner: true,
  savedPayments: false,
  instorePayment: false,
  instorePaymentID: 0,
  accounts: [],
  selectedAccount: null,
  supports: null,
  giftcard: null,
  opaque: false,
  timePicker: false,
  orderTypePicker: false,
  addressPicker: false,
  addingProduct: false,
  activeSection: 'le-first-chomp',
  topBanner: {}
})

export const mutations = {
  setClient(state, str) {
    state.client = str
  },

  setEnv(state, str) {
    state.env = str
  },

  setLoading(state, bool) {
    state.loading = bool
  },

  setErrors(state, arr) {
    state.errors = arr
  },

  setDeliveryErrors(state, arr) {
    state.deliveryErrors = arr
  },

  setLocation(state, obj) {
    obj.expires = moment().add(7, 'days').format('LLLL')
    state.location = obj
    localStorage.setItem(`${state.options.appPrefix}_loc`, JSON.stringify(obj))
    // if(oloList.indexOf(obj.id) > -1) {
      if(state.token && state.token.provider !== 'olo') {
        state.token = null
        state.user = null
        localStorage.removeItem(`${state.options.appPrefix}_tok`)
        localStorage.removeItem(`${state.options.appPrefix}_user`)
      }
    // } else {
    //   if(state.token && state.token.provider !== 'onosys') {
    //     state.token = null
    //     state.user = null
    //     localStorage.removeItem(`${state.options.appPrefix}_tok`)
    //     localStorage.removeItem(`${state.options.appPrefix}_user`)
    //   }
    // }
  },

  setLocationList(state, something) {
    state.locationList = something
  },

  setAllLocations(state, arr) {
    state.allLocations = arr
  },

  setDefaultMenu(state, obj) {
    state.defaultMenu = obj
  },

  setMenu(state, obj) {
    if(obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_menu`)
    } else {
      obj.expires = moment().add(1, 'days').format('LLLL')
      localStorage.setItem(`${state.options.appPrefix}_menu`, JSON.stringify(obj))
    }
    state.menu = obj
  },

  setActiveSection(state, str) {
    state.activeSection = str
  },

  setOrder(state, obj) {
    if(obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_order`)
    } else {
      obj.expires = moment().add(1, 'days').format('LLLL')
      localStorage.setItem(`${state.options.appPrefix}_order`, JSON.stringify(obj))
    }
    state.order = obj
  },

  setCheckoutStep(state, str) {
    state.checkoutStep = str
  },

  setHandoff(state, str) {
    state.order.handoff = str
  },

  setPreSubmitting(state, bool) {
    state.presubmitting = bool
  },

  setSubmitting(state, bool) {
    console.log('setSubmitting', bool)
    state.submitting = bool
  },

  setShowCart(state, bool) {
    state.showCart = bool
  },

  setUser(state, obj) {
    if(obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_user`)
    } else {
      localStorage.setItem(`${state.options.appPrefix}_user`, JSON.stringify(obj))
    }
    state.user = obj
  },

  setToken(state, obj) {
    if (obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_tok`)
    } else {
      localStorage.setItem(`${state.options.appPrefix}_tok`, JSON.stringify(obj))
    }
    state.token = obj
  },

  setOrderHistory(state, arr) {
    state.orderHistory = arr
  },

  setRedirect(state, str) {
    state.redirect = str
  },

  setNavMenus(state, obj) {
    state.navMenus = obj
  },

  setPromo(state, obj) {
    state.promo = obj
  },

  setShowBanner(state, bool) {
    state.showBanner = bool
  },

  setTopBanner(state, obj) {
    state.topBanner = obj
  },

  setOptions(state, obj) {
    state.options = obj
  },

  setIcons(state, obj) {
    state.icons = obj
  },

  setShowNav(state, bool) {
    state.showNav = bool
  },

  setSavedPayments(state, bool) {
    state.savedPayments = bool
  },

  setInstorePayment(state, bool) {
    state.instorePayment = bool
  },

  setInstorePaymentID(state, int) {
    state.instorePaymentID = int
  },

  setAccounts(state, arr) {
    state.accounts = arr
  },

  setSelectedAccount(state, str) {
    state.selectedAccount = str
  },
  
  setSupports(state, obj) {
    state.supports = obj
  },

  setGiftcard(state, obj) {
    state.giftcard = obj
  },

  setOpaque(state, obj) {
    state.opaque = obj
  },

  setTimePicker(state, bool) {
    console.log('setTimePicker', bool)
    state.timePicker = bool
  },

  setOrderTypePicker(state, bool) {
    state.orderTypePicker = bool
  },

  setAddressPicker(state, bool) {
    state.addressPicker = bool
  },

  setAddingProduct(state, bool) {
    state.addingProduct = bool
  }
}

const setGlobals = async ({ context, commit }) => {
  let globals = await context.$storyblok.getStories('_globals')
  let menus = {}
  globals.map(g => {
    if(g.content.component == 'menu') {
      menus[g.slug] = g.content.links
    } else if(g.content.component == 'footer-menu') {
      menus[g.slug] = g.content.links
    } else if(g.content.component == 'bottom-menu') {
      menus[g.slug] = g.content.links
    } else if(g.content.component == 'global') {
      let options = {}
      g.content.options.map(o => {
        options[o.key] = o.value == 'yes' ? true : o.value == 'no' ? false : o.value
      })
      commit('setOptions', options)
    } else if(g.content.component == 'icons') {
      let icons = {
        logo: g.content.logo,
        alt_logo: g.content.alt_logo 
      }
      commit('setIcons', icons)
    } else if(g.content.component == 'menu-promo') {
      commit('setPromo', g.content)
    } else if(g.content.component == 'location_list') {
      commit('setAllLocations', g.content.locations)
    } else if(g.content.component == 'product_list') {
      commit('setDefaultMenu', g.content)
    }
  })
  commit('setNavMenus', menus)
  return true
}

export const actions = {
  async nuxtServerInit({ commit }, context ) {
    let globals = await context.$storyblok.getStories('_globals')
    let menus = {}
    globals.map(g => {
      if(g.content.component == 'menu') {
        menus[g.slug] = g.content.links
      } else if(g.content.component == 'mobile-menu') {
        menus[g.slug] = g.content.links
      } else if(g.content.component == 'footer-menu') {
        menus[g.slug] = g.content.links
      } else if(g.content.component == 'bottom-menu') {
        menus[g.slug] = g.content.links
      } else if(g.content.component == 'global') {
        let options = {}
        g.content.options.map(o => {
          options[o.key] = o.value == 'yes' ? true : o.value == 'no' ? false : o.value
        })
        commit('setOptions', options)
      } else if(g.content.component == 'icons') {
        let icons = {
          logo: g.content.logo,
          alt_logo: g.content.alt_logo 
        }
        commit('setIcons', icons)
      } else if(g.content.component == 'menu-promo') {
        commit('setPromo', g.content)
      } else if(g.content.component == 'location_list') {
        commit('setAllLocations', g.content.locations)
      } else if(g.content.component == 'product_list') {
        commit('setDefaultMenu', g.content)
      } else if (g.content.component == 'top-banner') {
        commit('setTopBanner', g.content)
      }
    })
    commit('setNavMenus', menus)
    await setGlobals({ context, commit})
  },
  async nuxtClientInit({ commit }, context) {
    const p = this.state.options.appPrefix
    if(localStorage.getItem(`${p}_loc`)) {
      commit('setLocation', JSON.parse(localStorage.getItem(`${p}_loc`)))
    }
    if(localStorage.getItem(`${p}_menu`)) {
      commit('setMenu', JSON.parse(localStorage.getItem(`${p}_menu`)))
    }
    if(localStorage.getItem(`${p}_order`)) {
      commit('setOrder', JSON.parse(localStorage.getItem(`${p}_order`)))
    }
    if(localStorage.getItem(`${p}_user`)) {
      commit('setUser', JSON.parse(localStorage.getItem(`${p}_user`)))
      commit('customer/setCustomerType', 'user')
    }
    if(localStorage.getItem(`${p}_tok`)) {
      commit('setToken', JSON.parse(localStorage.getItem(`${p}_tok`)))
    }
    if(process.env.NODE_ENV != 'development' && process.env.STORYBLOK_PREVIEW == 'true') {
      await setGlobals({ context, commit })
    }
  },
  async locationSearch({}, data) {
    await this.$api.findLocations(data)
  },
  async accountSignin({ commit }, data) {
    const token = await this.$api.loginUser(data)
    if(token) {
      const user = await this.$api.getUser()
      if(user) {
        commit('customer/setCustomerType', 'user')
        if(this.state.order) {
          this.$router.push('/order/checkout')
        } else {
          this.$router.push('/account')
        }
      }
    }
  },
  async accountSignup({ commit }, data) {
    data.phone = data.phone.replace(/[\(\)\-\s]/g, '')
    const newUser = await this.$api.createUser(data)
    if(newUser) {
      const user = await this.$api.getUser()
      if(user) {
        commit('customer/setCustomerType', 'user')
        if(this.state.order) {
          this.$router.push('/order/checkout')
        } else {
          this.$router.push('/account')
        }
      }
    }
  },
  async accountUpdate({ commit }, data) {
    data.phone = data.phone.replace(/[\(\)\-\s]/g, '')
    const update = await this.$api.updateUser(data)
    if(update) {
      if(update.tokens) {
        commit('setToken', update.tokens)
      }
      this.$router.push('/account')
    }
  },
  async accountSignout({ commit }) {
    commit('setUser', null)
    commit('setOrder', null)
    commit('setToken', null)
    commit('customer/setCustomerType', null)
    this.$router.push(this.state.options.signinPage)
  },
  async forgotPassword({}, data) {
    await this.$api.forgotPassword(data.email)
    return true
  },
  fanFave({}, fave) {
    return this.state.options.fanFaves.split(',').indexOf(fave) > -1
  },
  updateCustomer({ commit }, data) {
    commit('customer/setCustomer', data)
  },
  updateDelivery({ commit }, data) {
    commit('customer/setDeliveryAddress', data)
  },
  updatePayment({ commit }, data) {
    commit('customer/setPayment', data)
  }
}