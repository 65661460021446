//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapState } from 'vuex'

export default {
  props: {
    onSelect: {
      type: Function,
    },
    lang: {
      type: String,
      default: 'Update'
    }
  },
  computed: {
    ...mapState([ 'location', 'order', 'addingProduct' ]),
    hasDelivery() {
      if (this.location.services.dispatch) {
        return this.location.services.dispatch
      } else {
        return this.location.services.delivery
      }
    },
    hasPickup() {
      return this.location.services.pickup
    }
  },
  data() {
    return {
      orderType: null,
    }
  },
  async mounted() {
    if (!this.order) {
      await this.$api.getMenu()
      await this.$api.createOrder()
      console.log('Order: ', this.order)
    }
  },
  methods: {
    closeModal() {
      this.$store.commit('setOrderTypePicker', false)
    },
    // async setHandoff(type) {
    //   // let order = await this.$api.createOrder()
    //   // console.log(order)
    //   if(type == 'delivery' || type == 'dispatch') {
    //     // await this.$api.setHandoff(this.location.services.dispatch ? 'dispatch' : 'delivery')
    //     // this.$store.commit('setHandoff', this.location.services.dispatch ? 'dispatch' : 'delivery')
    //     this.$store.commit('setOrderTypePicker', false)
    //     this.$store.commit('setAddressPicker', true)
    //   } else {
    //     this.$store.commit('setOrderTypePicker', false)
    //     this.$store.commit('setTimePicker', true)
    //   }
    // },
    setDelivery() {
      console.log("Delivery")
      if(this.location) {
        this.$store.commit('setOrderTypePicker', false)
        this.$store.commit('setAddressPicker', true)
      } else {
        this.$router.push('/locations')
      }
    },
    setPickup() {
      console.log("Pickup")
      if(this.location) {
        this.$store.commit('setOrderTypePicker', false)
        this.$store.commit('setTimePicker', true)
      } else {
        this.$router.push('/locations')
      }
    },
  }
}
