//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
  export default {
    computed: {
      ...mapState(['location'])
    },
    props: {
      text: {
        type: String,
        default: 'Start Order'
      }
    },
    methods: {
      startOrder() {
        if(this.location) {
          this.$store.commit('setOrder', null)
          this.$store.commit('setOrderTypePicker', true)
        } else {
          this.$router.push('/locations')
        }
      }
    }
  }
