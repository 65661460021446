import { render, staticRenderFns } from "./default.vue?vue&type=template&id=5361c079&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Banner: require('/opt/build/repo/components/layout/Banner.vue').default,Header: require('/opt/build/repo/components/layout/Header.vue').default,StickyHeader: require('/opt/build/repo/components/common/StickyHeader.vue').default,BottomBanner: require('/opt/build/repo/components/layout/BottomBanner.vue').default,Footer: require('/opt/build/repo/components/layout/Footer.vue').default,OrderTypePicker: require('/opt/build/repo/components/order/OrderTypePicker.vue').default,AddressPicker: require('/opt/build/repo/components/order/AddressPicker.vue').default,DateTimePicker: require('/opt/build/repo/components/order/DateTimePicker.vue').default,Loader: require('/opt/build/repo/components/common/Loader.vue').default,ErrorOverlay: require('/opt/build/repo/components/layout/ErrorOverlay.vue').default})
