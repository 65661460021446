//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { version } from '../../package.json';
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'options', 'navMenus' ]),
    year() {
      return moment().year()
    },
    leftCount() {
      return  Math.round(this.menu.length/2)
    },
    menu() {
      return this.navMenus['footer-menu']
    },
    bottomMenu() {
      return this.navMenus['bottom-menu']
    },
    socialMenu() {
      return this.navMenus['social-menu']
    },
    appVersion() {
      return process.env.APP_VERSION || '';
    }
  },
  data() {
    return {
      mq: this.$mq
    }
  },
}
