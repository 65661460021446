//
//
//
//
//
//
//
//
//
//
//
//

export default {
  mounted() {
    // document.querySelector('#__layout > div').classList.add('opaque')
  }
}
