//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex"

export default {
  data() {
    return {
      activeKey: null,
      timeout: null,
    }
  },
  computed: {
    ...mapState([
      "navMenus",
      "promo",
      "defaultMenu",
      "menu",
      "mobile-menu",
      "location",
      "order",
      "customer",
      "options",
      "cartCount",
      "user",
    ]),
    links() {
      if (this.$breakpoints.width < 900) {
        return this.navMenus["mobile-menu"] ? this.navMenus["mobile-menu"] : []
      } else {
        return this.navMenus["main-menu"] ? this.navMenus["main-menu"] : []
      }
    },
    socialMenu() {
      return this.navMenus["social-menu"] ? this.navMenus["social-menu"] : []
    },
    bottomMenu() {
      return this.navMenus["bottom-menu"]
    },
    footerMenu() {
      return this.navMenus['footer-menu'].slice(2)
    },
    displayMenu() {
      return this.defaultMenu
    },
    cartLink() {
      let link = this.options.orderPage
      if (this.order && this.order.products.length > 0) {
        if (this.customer.type != null) {
          link = this.options.orderCheckoutPage
        } else if (this.options.guestCheckout) {
          link = this.options.orderAccountPage
        } else {
          link = this.options.signinPage
        }
      }
      return link;
    },
  },
  methods: {
    slug(str) {
      str = str.toString().replace(/^\s+|\s+$/g, "");
      str = str.toLowerCase();
      let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      let to = "aaaaeeeeiiiioooouuuunc------";
      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }
      str = str
        .replace(/[^a-z0-9 -]/g, "")
        .replace(/\s+/g, "-")
        .replace(/-+/g, "-");

      return str
    },
    itemTo(c) {
      let to = "#" + this.slug(c);
      if (this.menu) {
        to = "/order/" + to
      } else {
        to = "/menu/" + to
      }
      return to
    },
    handleClick(i, url, sublinks) {
      if (!sublinks || this.$mq == "lg" || this.$mq == "xl") {
        if (this.externalURL(url)) {
          location.href = url
        } else {
          this.$router.push({
            path: "/" + url,
          });
        }
        this.activeKey = null;
      }
      if (this.$mq == "sm" || this.$mq == "md") {
        if (i == this.activeKey) {
          this.activeKey = null;
          if (this.externalURL(url)) {
            location.href = url;
          } else {
            this.$router.push({
              path: "/" + url,
            });
          }
          this.$store.commit("setShowNav", false);
        } else {
          this.activeKey = i;
        }
      }
    },
    internalURL(srt) {
      return str.replace("menu/")
    },
    externalURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" +
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
          "((\\d{1,3}\\.){3}\\d{1,3}))" +
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
          "(\\?[;&a-z\\d%_.~+=-]*)?" +
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      return !!pattern.test(str)
    },
  },
  watch: {
    "$route.path": function () {
      this.activeKey = null
    },
  },
};
