export default (context, inject) => {
  const feedback = {
    async send(form, fields) {
      let config = {
        method: 'post',
        url: `${window.location.origin}/feedback`,
        headers: {
          form,
          verify: await context.$recaptcha.execute('login')
        },
        data: fields
      }
      // if(process.env.REQUIRE_VERIFY == 'true') {
      //   config.headers = {
      //     verify: await context.$recaptcha.execute('login')
      //   }
      // }

      context.store.commit('setErrors', [])
      context.store.commit('setLoading', true)

      const submission = await context.$axios(config)
        .then(res => {
          return 'success'
        })
        .catch(err => {
          return err.response.data
        })

      context.store.commit('setLoading', false)
      if(submission == 'success') {
        return true
      } else {
        context.store.commit('setErrors', [submission.message])
        return false
      }
    },
    async resolution(data, complaint, secret) {
      let config = {
        method: 'post',
        url: `${window.location.origin}/resolution`,
        data,
        params: {
          complaint,
          secret
        }
      }

      const result = await context.$axios(config)
        .then(res => {
          return 'success'
        })
        .catch(err => {
          return err.response.data
        })
      if(result == 'success') {
        return true
      } else {
        context.store.commit('setErrors', [result.message])
        return false
      }
    }
  }

  inject('feedback', feedback)
  context.$feedback = feedback
}