import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=50d2029a&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Header.vue?vue&type=style&index=1&id=50d2029a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50d2029a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Hamburger: require('/opt/build/repo/components/layout/Hamburger.vue').default,Logo: require('/opt/build/repo/components/layout/Logo.vue').default,NavMenu: require('/opt/build/repo/components/layout/NavMenu.vue').default,UserIcon: require('/opt/build/repo/components/layout/UserIcon.vue').default,CartIcon: require('/opt/build/repo/components/layout/CartIcon.vue').default,StartButton: require('/opt/build/repo/components/layout/StartButton.vue').default,Header: require('/opt/build/repo/components/layout/Header.vue').default})
